<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES COMPTES</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <template>
                    <v-btn color="primary" dark class="mb-2" @click="nouveau()">
                        <v-icon dark>mdi-plus</v-icon> Nouveau
                    </v-btn>

                </template>                


                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>Formulaire de Compte</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field v-model="detail_Formulaire.nom_prenom" label="Nom et prenom" outlined></v-text-field>
                            <v-text-field v-model="detail_Formulaire.email" label="E-mail" outlined></v-text-field>
                            <v-text-field v-model="detail_Formulaire.password" label="Mot de passe" outlined></v-text-field>
                            <v-text-field v-model="detail_Formulaire.cpassword" label="Confirmez le mot de passe" outlined></v-text-field>
                            
                            
                            <v-select :items="list_profils" v-model="detail_Formulaire.profil" item-value="id" dense outlined label="Profil compte">
                                <template slot="selection" slot-scope="data">
                                    {{ data.item.libelle }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ data.item.libelle }}
                                </template>

                            </v-select>
                            
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
               
               
                <v-dialog v-model="dialog2" max-width="500px">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>Formulaire de Compte</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialog2 = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select :items="list_partenaire" v-model="selectpartenaire" item-value="id" dense outlined label="Liste partenaires">
                                <template slot="selection" slot-scope="data">
                                    ({{ data.item.code_partenaire }}) {{ data.item.noms }}  {{ data.item.prenoms }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    ({{ data.item.code_partenaire }}) {{ data.item.noms }}  {{ data.item.prenoms }}
                                </template>

                            </v-select>
                                    </v-col>
                                 
                               
                                
                        </v-row>
                               
                            
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="Associer_compte_partenaire()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

              
            </v-toolbar>
        </template>
        <template v-slot:item.profils="{ item }">
            <v-chip color="green" dark>
                {{ item.profils.libelle}}
            </v-chip>
        </template>
        <template v-slot:item.status="{ item }">
            <v-switch v-model="item.statut"  :label="item.status==1?'Activer':'Desactiver'" inset @change="Action_status(item)">
            
            </v-switch>

        </template>

        
        <template v-slot:item.id="{ item }">
            <v-btn icon @click="Suivant_security(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-server-security
                </v-icon>
            </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="detail(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>
            <v-col v-if="item.profil==2?true:item.profil==4?true:false">

                <v-btn icon @click="editpartenaire(item)">
                <v-icon color="orange" large>
                    mdi-set-left-center
                </v-icon>
            </v-btn>
            </v-col>

            

        </template>
       </v-data-table>

</div>
</template>

    
<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Gestion des clients",
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "center",
                sortable: false,
                value: "actions",
                divider: true,
                width: '15%'

            },
            
            {
                text: "Droit Access",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '4%'
            },
            {
                text: "status",
                value: "status",
                divider: true,
                width: '5%'
            },
           
            {
                text: "NOM",
                align: "start",
                sortable: false,
                value: "nom_prenom",
                divider: true,
                width: '150px'
            },
           
            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "email",
                divider: true,
                width: '150px'
            },
            {
                text: "PROFIL",
                align: "start",
                sortable: false,
                value: "profils",
                divider: true,
                width: '20%'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            nom_prenom: '',
            email: '',
            password: '',
            cpassword: '',
        },

        list: [],
        list2: [],
        list_profils: [],
        selectprofil: 0,
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],
        valeur: '',
        etat: false,
        selecttype_recherche: '',
        libelle_recherche: '',
        list_status: ['Valider', 'Bloquer','En attente'],
        list_partenaire: [],
        selectpartenaire: 0,

    }),

    created() {
        this.readAll_profils();
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            const data = await api.readAll('backoffice/compte-admins', fd);
            this.list2 = data;
            for (let i = 0; i < this.list2.length; i++) {
                this.list2[i]['statut'] = this.list2[i].status == 1 ? true : false;
            }
            this.list = this.list2;

        },
        readAll_profils: async function () {
            
            const data = await api.readAll('backoffice/all-profil');
            this.list_profils = data;

            let fd = new FormData();
            const data2 = await api.createUpdatedata('backoffice/all-partenaire', fd);
            this.list_partenaire = data2;

        },

        detail: async function (item) {
            this.detail_Formulaire.nom_prenom = item.nom_prenom;
            this.detail_Formulaire.email = item.email;
            this.detail_Formulaire.id = item.id;
            this.detail_Formulaire.profil = item.profils.id;
            this.etat = true;
            this.dialog = true;
        },

        save: async function () {
         if(this.detail_Formulaire.cpassword!=this.detail_Formulaire.password){
            this.showAlert('Les mots de passe ne sont pas identiques', 'error');
            return;
         }
        let fd = new FormData();
        fd.append('nom_prenom', this.detail_Formulaire.nom_prenom);
        fd.append('email', this.detail_Formulaire.email);
        fd.append('password', this.detail_Formulaire.password);
        fd.append('profil', this.detail_Formulaire.profil);
        if(this.etat==false){
            const data = await api.createUpdatedata('backoffice/compte-admins-register', fd);
        
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.dialog = false;
                this.readAll();
                this.clearItem();
            } else {
                this.clearItem();
                this.showAlert(data.message, 'error');
            }
        }else{
            const data = await api.createUpdatedata('backoffice/compte-admins-update/'+this.detail_Formulaire.id, fd);
        
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.dialog = false;
                this.readAll();
                this.clearItem();
            } else {
                this.showAlert(data.message, 'error');
            }
        }
        
       
    },
    nouveau: async function () {
       // this.clearItem();
        this.dialog = true;
        this.etat = false;
    },

    clearItem(){

        this.detail_Formulaire={
            id: '',
            nom_prenom: '',
            email: '',
            password: '',
            cpassword: '',
        }
      
    },

        async Action_status(item) {
        const data= await  api.readAll('backoffice/compte-admins-status/'+item.id);
        if(data.status==true){
            this.showAlert(data.message, 'success');
            this.readAll();
        }else{
            this.showAlert(data.message, 'error');
        }
        },

        editpartenaire(item) {
          this.detail_Formulaire.id=item.id;
          this.dialog2 = true;
        },
        Associer_compte_partenaire: async function () {
            let fd = new FormData();
            fd.append('id_compte', this.detail_Formulaire.id);
            fd.append('id_partenaire', this.selectpartenaire);
            const data= await api.createUpdatedata('backoffice/add-compte-partenaire', fd);
            if(data.status==true){
                this.showAlert(data.message, 'success');
                this.dialog2 = false;
                this.readAll();
            }else{
                this.showAlert(data.message, 'error');
            }

        },
        Suivant_security(item) {
            this.$router.push({
                path: 'droits-access/' + item.id,
                params: {
                    id: item.id
                }
            });
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
